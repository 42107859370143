import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PADropZone from '../operations/paParser/PADropZone'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import {
  HOME, ANALYTICS, SALES, OPERATIONS, HUMAN_RESOURCES, SETTINGS,
  PRODUCTION_VIEW, INFORMATION, MANAGEMENT
} from '../../constants/servicesAndRoles';

import Home from '../home/Home';
import Analytics from '../analytics/Analytics';
import Sales from '../sales/Sales';
import HumanResources from '../humanResources/HumanResources';
import Settings from '../settings/Settings';
import Operations from '../operations/Operations';
import ProductionView from '../operations/ProductionView';
import Information from '../information/Information';
import Mgmt from '../mgmt/Mgmt';

export const ServiceSwitch = () => {
  const service = useSelector((state: any) => state.serviceReducer.service)
  const [selectedService, setSelectedService] = useState(<Home />)

  useEffect(() => {
    switch(service){
      case HOME:
        setSelectedService(<Home />)
        break;
      case ANALYTICS:
        setSelectedService(<Analytics />)
        break;
      case SALES:
        setSelectedService(<Sales />)
        break;
      case OPERATIONS:
        setSelectedService(<Operations />)
        break;
      case HUMAN_RESOURCES:
        setSelectedService(<HumanResources />)
        break;
      case SETTINGS:
        setSelectedService(<Settings />)
        break;
      case PRODUCTION_VIEW:
	setSelectedService(<ProductionView />)
	break;
      case INFORMATION:
	setSelectedService(<Information />)
	break;
      case MANAGEMENT:
	setSelectedService(<Mgmt />)
	break;
      default:
        setSelectedService(<Home />)
        break;
    }
  }, [service])



  return(
    selectedService
  )
}
