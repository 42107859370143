import React, { useState } from 'react';
import { useStyles } from '../../hooks/useStyles';
import Grid from '@material-ui/core/Grid';
import { Box, Paper }from '@material-ui/core';
import { DropZone }from '../appTools/Dropzone';
import { XLSX_UPLOAD_OBJECT } from '../appTools/consts';
import { DropZonePropTypes } from '../appTools/types';
import { pickService } from '../../redux/services/actions'
import { useDispatch } from 'react-redux'
import { PRODUCTION_VIEW } from '../../constants/servicesAndRoles';
import { afiMonoApiURL } from '../../utils/rootURLs';
import PADropZone from './paParser/PADropZone';


const PA_PARSER = 'PA_PARSER'

const Operations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [focusedModule, setFocusedModule] = useState()

  return(
    <Grid container spacing={5}>
	<Grid
	  item
	  container
	  xs={12}
	  justify="center"
	  alignItems="center"
	>
	      <PADropZone/>
	</Grid>
    </Grid>
  )

}

export default Operations;


/**
	  <Paper
	    onClick={() => dispatch(pickService(PRODUCTION_VIEW))}
	    className={classes.paper}>
	  <h1>Check Production Statistics</h1>
	</Paper>
	</Grid>
	<Grid
	  item
	  xs={6}
	>
	<Paper className={classes.paper}>
	  <DropZone
	    uploadObject={XLSX_UPLOAD_OBJECT}
	    destinationURL={afiMonoApiURL}
	  />
	</Paper>
	</Grid>
	<Grid
	item
	xs={6}
	direction="row"
	justify="space-around"
	alignItems="center"
	>
	  <Paper className={classes.paper}>
	    <DropZone
	      uploadObject={XLSX_UPLOAD_OBJECT}
	      destinationURL={afiMonoApiURL}
	      />
	  </Paper>
	</Grid>
	<Grid
	item
	xs={6}
	direction="row"
	justify="space-around"
	alignItems="center"
	>
	  <Paper className={classes.paper}>
	    <DropZone
	      uploadObject={XLSX_UPLOAD_OBJECT}
	      destinationURL={afiMonoApiURL}
	      />
	  </Paper>

*/
