import React from 'react';

const Settings= () => {
  return(
    <>
      <h1>You are viewing an empty Settings section.</h1>
      <h2>There are functional elements in the Sales and Operations sections.</h2>
    </>
  )
}

export default Settings;
