//Empty comment
import React from 'react';
import Box from '@material-ui/core/Box';
import { ErrorBox }from '../appTools/errorComponents';
import { useField, useFormik, Formik,
	 FormikHelpers, ErrorMessage, Field,
	 FieldAttributes, FieldInputProps,
	 FieldProps, useFormikContext
} from 'formik';
import { GenericProductForm } from './GenericProductForm';
import { PopInterface } from './infoTypes'


export const PopForm = () => {
  const { values, errors } = useFormikContext<PopInterface>();

  return(
    <Box display="flex" flexDirection="column">
      <label>Film Type:</label>
      <Field name="film_type" type="string" errors={errors.film_type} />
      {errors.film_type && <ErrorBox id="feedback">{errors.film_type}</ErrorBox> }
      <label>P182 Equivalence:</label>
      <Field name="p182_eq" type="number" errors={errors.p182_eq} />
      {errors.p182_eq && <ErrorBox id="feedback">{errors.p182_eq}</ErrorBox> }
      <GenericProductForm/>
    </Box>
  )
}


//<GenericProductForm />


