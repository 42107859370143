import React from 'react';

const Analytics= () => {
  return(
    <>
      <h1>You are viewing an empty Analytics section.</h1>
      <h2>There are functional elements in the Sales and Operations sections.</h2>
    </>
  )
}

export default Analytics;
