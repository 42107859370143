import { DropzoneUploadInterface } from './types';

export const PICTURE_UPLOAD_OBJECT: DropzoneUploadInterface = {
  acceptType: 'image/jpg, image/jpeg, image/png, image/svg+xml',
  acceptMessage: 'Upload a jpeg/jpg, svg, or png'
}

export const XLSX_UPLOAD_OBJECT: DropzoneUploadInterface = {
  acceptType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  acceptMessage: 'Update Production Values (Only XLSX accepted)'
}
