import React, { useEffect, useState } from 'react';
import {postRequest} from '../appTools/postRequest';

const blsTestData = {
  seriesid: ['CUUR0000SA0'],
  startyear: "2020",
  endyear: "2021",
  registrationkey: 'a6b96a84312141dfa9b3eb253f682bc7'
}

const HumanResources = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [blsStat, setBlsStat] = useState<any>(null)
  useEffect(() => {
    console.log('is loaded') 
    setBlsStat(() => postRequest(
      'https://api.bls.gov/publicAPI/v2/timeseries/data/',
      blsTestData
    ))
    setIsLoaded(true)
  },[])

  return(
    <>
      <button onClick={() => console.log(blsStat)}>BLS</button>
      <h1>You are viewing an empty Human Resources section.</h1>
      <h2>There are functional elements in the Sales and Operations sections.</h2>
    </>
  )
}

export default HumanResources;
