import React from 'react';
import Box from '@material-ui/core/Box';


export const ErrorBox = (props: any) => {
  return(
    <Box color="error.main">{props.children}</Box>
  )
}

export function decimalParser(value: number, max_digit: number, decimal_limit: number) {
  let initial_nums = 0
  let decimal_nums = 0
  let passed_decimal = false
  const numString = value.toString()
  for(let i = 0; i + 1 <= numString.length; i++){
    if(numString[i] == '.'){
      passed_decimal = true
      continue;
    }
    if(passed_decimal == true){
      decimal_nums++
    }else{
      initial_nums++
    }
  }
  if(decimal_nums > decimal_limit){
    return false
  } else if(initial_nums + decimal_nums > max_digit){
    return false
  } else {
    return true
  }
}
