import ImageCompression from "browser-image-compression";

const compressionOptions = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  fileType: 'jpg'
}

const ImageFileSet = new Set([
  'jpg',
  'jpeg',
  'JPG',
  'JPEG',
  'png',
  'PNG'
])


export const fileUpload = async (url: string, fileArray: [File], keycloak:
Keycloak.KeycloakInstance): Promise<any> => {
  let formData = new FormData();
  for(let i = 0; i < fileArray.length; i++){
    const filePrefix = fileArray[i].name.split('.').pop()
    if(typeof filePrefix === 'string'){
      if(ImageFileSet.has(filePrefix)){
	let imageToAppend = fileArray[i]
	imageToAppend = await ImageCompression(imageToAppend, compressionOptions)
	formData.append(fileArray[i].name, imageToAppend)
	console.log(fileArray[i].name)
      }else{
	formData.append(fileArray[i].name, fileArray[i])
      }
    }else{
      formData.append(fileArray[i].name, fileArray[i])
    }
  }
  return await new Promise(resolve => {
    fetch(url, {
      method: 'POST',
      body: formData
    })
	.then(response => response.json())
	.then(body => {
	  resolve(body);
	});
  });
};
