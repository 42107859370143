import React from 'react';

const Mgmt = () => {
  return(
    <>
      <h1>You are viewing an empty Management section.</h1>
    </>
  )
}

export default Mgmt;
