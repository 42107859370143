import { NO_JSON } from '../../constants/constants';
import React, { useState, useEffect } from 'react';
import { Carousel } from '../appTools/carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useKeycloak } from '@react-keycloak/web';
import { postRequest } from '../appTools/postRequest';
import { Formik,
	 FormikHelpers
} from 'formik';
import {getRequest} from '../appTools/getRequest';
import { aerosolReqURL, drinkReqURL, popReqURL, uploadObjectURL } from '../../utils/rootURLs';
import { useSelector } from 'react-redux';
import { useStyles } from '../../hooks/useStyles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MASTER_CODE_ADMIN} from '../../constants/servicesAndRoles';
import { DrinkInterface, PopInterface, AerosolInterface } from './infoTypes';
import { DrinkUpdateSchema, PopUpdateSchema, AerosolUpdateSchema } from './infoSchemas';
import { PopForm } from './PopForm';
import { AerosolForm } from './AerosolForm';
import { DrinkForm } from './DrinkForm';
import { PICTURE_UPLOAD_OBJECT } from 'components/appTools/consts';
import {DropZone} from '../appTools/Dropzone';
import { defaultFormValues, defaultPop } from './defaults';

const Information = () => {
  const classes = useStyles();
  const AEROSOL_CHOICE = 'AEROSOL_CHOICE'
  const WATER_CHOICE = 'WATER_CHOICE'
  const DRINK_CHOICE = 'DRINK_CHOICE'
  const POP_CHOICE = 'POP_CHOICE'
  const roles = useSelector((state: any) => state.roleReducer.roles)
  const [chosenItem, setChosenItem] = useState(POP_CHOICE)
  const [aerosolCodeArray, setAerosolCodeArray] = useState<Array<AerosolInterface>>([])
  const [waterCodeArray, setWaterCodeArray] = useState<Array<DrinkInterface>>([])
  const [drinkCodeArray, setDrinkCodeArray] = useState<Array<DrinkInterface>>([])
  const [popCodeArray, setPopCodeArray] = useState<Array<PopInterface>>([])
  const [selectedArray, setSelectedArray] = useState<Array<any>>([])
  const [focusedItem, setFocusedItem] = useState(Object)
  const [focusedPictures, setFocusedPictures] = useState<Array<string>>([])
  useEffect(() => {
    switch (focusedItem.item_code){
      case undefined:
	break;
      default:
	getRequest(`${uploadObjectURL}${focusedItem.item_code}/`).then(
	(data: any) => {
	    if (data != NO_JSON) {
	    setFocusedPictures(data.image_urls)
	    }else{
	    setFocusedPictures([])
	    }
	}
	)
    }
  }, [focusedItem])

  const DISPLAY_ITEM_LIST = 'DISPLAY_ITEM_LIST';
  const FOCUS_ITEM = 'FOCUS_ITEM';
  const EDIT_ITEM = 'EDIT_ITEM';
  const ADD_ITEM = 'ADD_ITEM';
  const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
  const [displayChoice, setDisplayChoice] = useState(DISPLAY_ITEM_LIST)
  const {keycloak, initialized} = useKeycloak();


  const switchProductCategory = (itemChoice: string, arrayData: any) => {
    setChosenItem(itemChoice)
    switch (itemChoice) {
      case AEROSOL_CHOICE:
	setAerosolCodeArray(arrayData)
	setSelectedArray(arrayData)
	break;
      case WATER_CHOICE:
	let waterArray = arrayData.filter((datum: any) => datum.is_water == true)
	console.log(waterArray)
	setWaterCodeArray(waterArray)
	setSelectedArray(waterArray)
	break;
      case DRINK_CHOICE:
	let drinkArray = arrayData.filter((datum: any) => datum.is_water != true)
	console.log(drinkArray)
	setDrinkCodeArray(drinkArray)
	setSelectedArray(drinkArray)
	break;
      case POP_CHOICE:
	setPopCodeArray(arrayData)
	setSelectedArray(arrayData)
	break;
    }
  }

  const returnSchema = () => {
    switch(chosenItem) {
      case AEROSOL_CHOICE:
	return AerosolUpdateSchema 
      case WATER_CHOICE:
	return DrinkUpdateSchema
      case DRINK_CHOICE:
	return DrinkUpdateSchema
      case POP_CHOICE:
	return PopUpdateSchema
    }
  }


  const switchToItemFocus = (itemObject?: any) => {
    if(itemObject){
      setFocusedItem(itemObject)
    }
    setDisplayChoice(FOCUS_ITEM)
    console.log(focusedItem)
  }

  const switchToItemArray = () => {
    setDisplayChoice(DISPLAY_ITEM_LIST)
  }

  const switchToItemEdit = () => {
    setDisplayChoice(EDIT_ITEM)
  }

  const switchToPictureUpload = () => {
    setDisplayChoice(UPLOAD_PICTURE)
  }

  const ItemButton = ({itemName, itemObject} : {
    itemName: string,
    itemObject: any,
    className?: any
  }) => {
    return(
      <Button
	variant="contained"
	color="primary"
	onClick={() => switchToItemFocus(itemObject)}
      >{itemName} </Button>
    )}
  /***

  */


  // Display array of items as a set of buttons.
  const ArrayDisplay = () => {
    return(
      <Box
	display="flex"
	flexDirection="column"
	alignItems="center"
	p={1}
      >
	{roles.includes(MASTER_CODE_ADMIN)
	?
	<Button
	  variant="contained"
	  color="secondary"
	  size="medium"
	  onClick={() => setDisplayChoice(ADD_ITEM)}
	  style={{
	    maxWidth: '100px',
	    maxHeight: '50px'
	  }}
	>Add Item</Button>
	: null}
	<Container className={classes.root}>
	    {selectedArray.map((arrElement) =>
	    <ItemButton
		itemName={arrElement['item_code']}
		itemObject={arrElement} 
	    />
	    )}
	</Container>
      </Box>
    )
  }


  const ItemPaper = styled(Paper)({
    padding: '30px',
  })

  // Display the information for a selected item.
  const ItemDisplay = () => {
    let keyArray = Object.keys(focusedItem)

    return(
      <ItemPaper>
      <Box display="flex" flexDirection="column">
	    <Box display="flex" flexDirection="row">
		<Button
		    variant="contained"
		    color="primary"
		    onClick={() => switchToItemArray()}
		>Back</Button>
	    {roles.includes(MASTER_CODE_ADMIN)
	    ? 
	    <>
		<Button
		    variant="contained"
		    color="primary"
		    onClick={() => switchToItemEdit()}
		>Edit Item Info</Button>
		<Button
		    variant="contained"
		    color="primary"
		    onClick={() => switchToPictureUpload()}
		>
	    Manage Pictures
	    </Button>
	    </>
	    : null}
	    </Box>
      <Box display="flex" flexDirection="row">
      <Carousel pictureArray={focusedPictures} />
      </Box>
	    {keyArray.map((keyVal) =>
	      <h4>{keyVal}: {
		focusedItem[keyVal] != null
		? focusedItem[keyVal].toString()
		: 'null'
	      }</h4>
	    )}
	</Box>
      </ItemPaper>
      )}

  const renderForm = () => {
    let formHolder = null
    switch (chosenItem) {
      case POP_CHOICE:
	formHolder = <PopForm />
	break;
      case AEROSOL_CHOICE:
	formHolder = <AerosolForm />
	break;
      case DRINK_CHOICE:
	formHolder = <DrinkForm />
	break;
      case WATER_CHOICE:
	formHolder = <DrinkForm />
	break;
      default:
	formHolder = <h1>Empty line</h1>
	break;
    }
    return (
      <>
	<Grid
	  container
	  spacing={1}
	  >
	  <Grid item xs={3}>
	    <Button
	      variant="contained"
	      color="primary"
	      onClick={displayChoice == EDIT_ITEM ?
		       () => switchToItemFocus()
			   : () => setDisplayChoice(DISPLAY_ITEM_LIST)
	      }
	    >Back</Button>
	  </Grid>
	  <Grid item xs={9}>
	    <Box display="flex" flexDirection="row" justifyContent="space-between">
	      {displayChoice == ADD_ITEM ?
	       <></> :
	       <>
		 <Box fontSize={19} fontStyle="oblique" fontWeight="fontWeightBold" mr={3}>Editing: {focusedItem['item_code']}</Box>
		 <Box fontSize={19}>Last Edited By: {focusedItem['last_edit_author']}</Box>
	       </>
	      }
	    </Box>
	  </Grid>
	</Grid>
	{formHolder}
      </>
    )
  }


  // Display a form for editing an item.
  const ItemFormDisplay = () => {
    return(
      <div>
	<Formik
	    initialValues={displayChoice == EDIT_ITEM ? focusedItem : defaultFormValues}
	    validationSchema={returnSchema }
	    onSubmit={(
	    values: AerosolInterface | PopInterface | DrinkInterface,
	    actions: FormikHelpers<AerosolInterface | PopInterface | DrinkInterface>
	    ) => {
	      if (keycloak.tokenParsed) {
		values.last_edit_author = `${keycloak.tokenParsed.sub}`
	      }
	      let postResponse;
	      let editedItemIndex;
	      let newArr;
	      let resolvedValues;
	      switch (chosenItem) {
		case POP_CHOICE:
		  postResponse = postRequest(popReqURL, values)
		  editedItemIndex = popCodeArray.findIndex(pop =>  pop.item_code ===  values.item_code)
		  newArr = [...popCodeArray]
		  resolvedValues = values as PopInterface
		  newArr[editedItemIndex] = resolvedValues
		  setPopCodeArray([...newArr])
		  setSelectedArray([...newArr])
		  break;
		case AEROSOL_CHOICE:
		  postResponse = postRequest(aerosolReqURL, values)
		  editedItemIndex = aerosolCodeArray.findIndex(aerosol =>  aerosol.item_code ===  values.item_code)
		  newArr = [...aerosolCodeArray]
		  resolvedValues = values as AerosolInterface
		  newArr[editedItemIndex] = resolvedValues
		  setAerosolCodeArray([...newArr])
		  setSelectedArray([...newArr])
		  break;
		case WATER_CHOICE:
		case DRINK_CHOICE:
		  postResponse = postRequest(drinkReqURL, values)
		  editedItemIndex = drinkCodeArray.findIndex(drink => drink.item_code ===  values.item_code)
		  newArr = [...drinkCodeArray]
		  resolvedValues = values as DrinkInterface
		  newArr[editedItemIndex] = resolvedValues
		  setDrinkCodeArray([...newArr])
		  setSelectedArray([...newArr])
		  break;
		default:
		  break;
	    }
	      setDisplayChoice(DISPLAY_ITEM_LIST)
	    }}
	>
	  {props => (
	    <form onSubmit={props.handleSubmit}>
	      {renderForm()}
	      <button type="submit">Submit</button>
	  </form>
	)}
	</Formik>
      </div>
    )
  }


  const UploadPicture = () => {
    const uploadURL = `${uploadObjectURL}${focusedItem.item_code}/`
    return (
      <div>
	<Button
	  variant="contained"
	  color="primary"
	  onClick={() => switchToItemFocus() }
	>Back</Button>
	<DropZone
	uploadObject={PICTURE_UPLOAD_OBJECT}
	destinationURL={uploadURL}
	/>
      </div>
    )
  }

  // {renderForm()}
  const renderDisplay = () => {
    switch(displayChoice){
      case FOCUS_ITEM:
	console.log(typeof focusedItem.is_water)
	return <ItemDisplay />
      case DISPLAY_ITEM_LIST:
	return <ArrayDisplay />
      case EDIT_ITEM:
	return <ItemFormDisplay/>
      case ADD_ITEM:
	return <ItemFormDisplay/>
      case UPLOAD_PICTURE:
	return <UploadPicture />
    }
  }



  return(
	<Grid
	  className={classes.root}
	  container
	  spacing={1}
	  direction="column"
	  justify="space-around"
	  alignItems="stretch"
	>
	  <Paper
	    className={classes.paper}>
	    <h1>Find item codes</h1>
	    <Grid
	      container
	      spacing={1}
	      xs={12}
	      direction="row"
	      justify="space-around"
	      alignItems="center">
	      <Button
		variant="contained"
		color="primary"
		onClick={() => getRequest(aerosolReqURL).then(
		  data => switchProductCategory(AEROSOL_CHOICE, data))}
	      >Aerosol</Button>
	      <Button
		variant="contained"
		color="primary"
		onClick={() => getRequest(popReqURL).then(
		  data => switchProductCategory(POP_CHOICE, data))}
	      >Pops</Button>
	      <Button
		variant="contained"
		color="primary"
		onClick={() => getRequest(drinkReqURL).then(
		  data => switchProductCategory(WATER_CHOICE, data))}
	      >Water</Button>
	      <Button
		variant="contained"
		color="primary"
		onClick={() => getRequest(drinkReqURL).then(
		  data => switchProductCategory(DRINK_CHOICE, data))}
	      >Drinks</Button>
	    </Grid>
	  </Paper>
	  {renderDisplay()}
	</Grid>
  )
}

  /***

  **/

export default Information;
