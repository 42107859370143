import {useState} from 'react';
import {
  Grid, Box
}from '@material-ui/core';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const arrowStyles = {
  root: {
    width: 200,
    height: 200 
  }
}

const StyledArrowLeft = withStyles(arrowStyles)(ArrowLeft)

const StyledRightArrow = withStyles(arrowStyles)(ArrowRight)

export const Carousel = ({pictureArray}: {
  pictureArray: string[]
}) => {
  const [currentURL, setCurrentUrl] = useState(pictureArray[0])
  const [pictureIndex , setPictureIndex] = useState(0)

  function pictureDecrement(){
    if(pictureIndex == 0){
      return 
    }else{
      setPictureIndex(pictureIndex - 1)
      console.log(pictureIndex)
      console.log(pictureArray[pictureIndex])
    }
  }

  function pictureIncrement(){
    if(pictureIndex == pictureArray.length - 1){
      return
    }else{
      setPictureIndex(pictureIndex + 1)
      console.log(pictureIndex)
      console.log(pictureArray[pictureIndex])
    }
  }

  return (
    <Grid container xs={12}>
	<Grid container item xs={3} justify="center" alignItems="center">
	    <StyledArrowLeft
	    onClick={() => pictureDecrement()}
	    />
	</Grid>
	<Grid container item xs={6} justify="center" alignItems="center">
	    <img
	    height="400"
	    width="400"
	    src={pictureArray[pictureIndex]}
	    />
	</Grid>
	<Grid container item xs={3} justify="center" alignItems="center">
	    <StyledRightArrow
	    onClick={() => pictureIncrement()}
	    />
	</Grid>
    </Grid>
  )
}
