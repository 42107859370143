import React, { useCallback, useState } from 'react';
import { DropzoneUploadInterface, DropZonePropTypes } from './types';
import { useDropzone } from 'react-dropzone';
import { fileUpload } from './fileUpload';
import { useKeycloak } from '@react-keycloak/web';



export const DropZone = ({uploadObject, destinationURL}: DropZonePropTypes ) => {
  const [serverResponse, setServerResponse] = useState('')
  const {keycloak} = useKeycloak()
  const [isLoading, setIsLoading] = useState(false);
  const acceptType: string = uploadObject.acceptType
  const message: string = uploadObject.acceptMessage
  const request: string = destinationURL;

  const onDrop = useCallback(acceptedFiles => {
    setIsLoading(isLoading => true)
    fileUpload(request, acceptedFiles, keycloak)
    .then(body => {
      setServerResponse(serverResponse => body.filename)
      setIsLoading(isLoading => false)
})
  }, []);


  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: acceptType
  });

  return(
    <div {...getRootProps()}>
      <input {...getInputProps()}/>
      {isDragActive ?
       <p>Drop your files here...</p> :
       <div>
	 <h1>{message}</h1>
	 <h2>Drag file into this region.</h2>
	 <h2>Or click here to browse for files.</h2>
       </div>
      }
      {isLoading ?
       <h5>Loading...</h5> :
       null
      }
      {serverResponse.length > 0 ?
	<h1>{serverResponse}</h1> :
	null
      }
    </div>
  )
}
