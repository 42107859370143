import * as Yup from 'yup';
import { decimalParser } from '../appTools/errorComponents';

export const ProductUpdateSchema = Yup.object().shape({
  item_code: Yup.string()
  .max(7, "Jeez, thats a long item_code! Try something shorter.")
  .min(2, "Please enter a minimum of two characters.")
  .required('Required'),
  label_no: Yup.number(),
  box_type: Yup.string()
  .max(140, "That's a long-winded description. Go for brevity.")
  .min(2, "Really? just a single character?"),
  upc: Yup.string()
  .max(30, "Do they even make upcs that long?"),
  units: Yup.number(),
  case_upc: Yup.string()
  .max(30, "Do they even make upcs that long?"),
  pallet_upc: Yup.string()
  .max(30, "Do they even make upcs that long?"),
  case_ink_jet_description: Yup.string()
  .max(240, "So long! Please limit your description to 240 characters."),
  brand: Yup.string()
  .max(240, "So long! Please limit your description to 240 characters."),
  case_weight: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 5 total digits with a maximum of 1 decimal place.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 5, 1)
			return returnValue
		      }
		    } 
  ),
  pallet_weight: Yup.number()
  .integer(),
  cases_per_pallet: Yup.number()
  .integer(),
  pallets_per_truck: Yup.number()
  .integer(),
  case_length: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 2 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 2)
			return returnValue
		      }
		    } 
  ),
  case_width: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 2 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 2)
			return returnValue
		      }
		    } 
  ),
  case_height: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 2 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 2)
			return returnValue
		      }
		    } 
  ),
  cube: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 7 total digits with a maximum of 3 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 7, 3)
			return returnValue
		      }
		    } 
  ),
  ti: Yup.number()
  .integer(),
  hi: Yup.number()
  .integer(),
  allergens: Yup.string()
  .max(140, "Is this the latin rendition of your allergen?! Keep it within 140 characters."),
  current_description: Yup.string()
  .max(140, "Provide a description, not a novel. Limit yourself to 240 characters."),
  organic: Yup.boolean(),
  gmo: Yup.boolean(),
  is_active: Yup.boolean(),
})


export const PopUpdateSchema = Yup.object().shape({
  film_type: Yup.string()
  .max(240, "Limit your film type description to 240 characters."),
  p182_eq: Yup.number()
	      .test(
		'is-correct',
		'Limit yourself to 7 total digits with a maximum of 5 decimal places.',
		(value) => {
		  if(value === undefined || value === null){
		    return false 
		  } else {
		    let returnValue = decimalParser(value, 7, 5)
		    return returnValue
		  }
		} 
  ),
})

export const AerosolUpdateSchema = ProductUpdateSchema.shape({
  valve_type: Yup.string() 
  .max(140, "Please limit your entry to 140 characters."),
  can_size: Yup.number()
  .integer("Please only enter whole numbers"),
  can_type: Yup.string()
  .max(140, "Please limit your entry to 140 characters."),
  cans_per_pallet_purchased: Yup.number()
  .integer("Please only enter whole numbers."),
  cap: Yup.string()
  .max(140, "Please limit your entry to 140 characters."),
  can_ink_jet_description: Yup.string()
  .max(240, "Please limit your entry to 240 characters."),
  can_fill_weight: Yup.number()
  .integer("Please only enter whole numbers."),
  shelf_life: Yup.number()
  .integer("Please only enter whole numbers."),
  formula_quant_per_bill: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 8 total digits with a maximum of 4 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 8, 4)
			return returnValue
		      }
		    } 
		  ),
  raw_cream_per_bill: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 4 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 4)
			return returnValue
		      }
		    } 
		  ),
  raw_skim_per_bill: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 4 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 4)
			return returnValue
		      }
		    } 
		  ),
  nitrogen_pounds_per_case: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 4 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 4)
			return returnValue
		      }
		    } 
		  ),
  nitrous_pounds_per_case: Yup.number()
		  .test(
		    'is-correct',
		    'Limit yourself to 6 total digits with a maximum of 4 decimal places.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let returnValue = decimalParser(value, 6, 4)
			return returnValue
		      }
		    } 
		  ),
  cans_per_case: Yup.number()
  .integer("Please only enter whole numbers"),
  formula_abbrev: Yup.string()
		  .test(
		    'is-correct',
		    'Please enter a value in the following format: ##.## XXXXXXXX.',
		    (value) => {
		      if(value === undefined || value === null){
			return false 
		      } else {
			let re = /^\d{1,2}.\d{1,2}\s[A-Z\s]+$/
			let reCheck = re.test(value)
			return reCheck
		      }
		    } 
		  )
})

export const DrinkUpdateSchema = ProductUpdateSchema.shape({
  cap_type: Yup.string()
  .max(140, "Please limit your entry to 140 characters."),
  bottles: Yup.number()
  .integer("Please only enter whole numbers"),
  is_water: Yup.boolean(),
  gtin: Yup.number()
  .integer("Please only enter whole numbers"),
  is_label_filmm: Yup.boolean(),
})
