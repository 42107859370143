//Empty comment
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { ErrorBox }from '../appTools/errorComponents';
import { useField, useFormik, Formik,
	 FormikHelpers, ErrorMessage, Field,
	 FieldAttributes, FieldInputProps,
	 FieldProps, useFormikContext
} from 'formik';

import {GenericProductInterface} from './infoTypes'



export const GenericProductForm = () => {
  const {values, errors} = useFormikContext<GenericProductInterface>();

  return(
    <>
        <label>Item Code:</label>
	<Field name="item_code" type="string" errors={errors.item_code} />
	{errors.item_code && <ErrorBox id="feedback">{errors.item_code}</ErrorBox> }
	<label>Label Number:</label>
	<Field name="label_no" type="number" errors={errors.label_no} />
	{errors.label_no && <ErrorBox id="feedback">{errors.label_no}</ErrorBox> }
	<label> Box Type:</label>
	<Field name="box_type" type="string" errors={errors.box_type} />
	{errors.box_type && <ErrorBox id="feedback">{errors.box_type}</ErrorBox> }
	<label>UPC:</label>
	<Field name="upc" type="string" errors={errors.upc} />
	{errors.upc && <ErrorBox id="feedback">{errors.upc}</ErrorBox> }
	<label>Units:</label>
	<Field name="units" type="number" errors={errors.units} />
	{errors.units && <ErrorBox id="feedback">{errors.units}</ErrorBox> }
	<label>Case UPC:</label>
	<Field name="case_upc" type="string" errors={errors.case_upc} />
	{errors.case_upc && <ErrorBox id="feedback">{errors.case_upc}</ErrorBox> }
	<label>Pallet UPC:</label>
	<Field name="pallet_upc" type="string" errors={errors.pallet_upc} />
	{errors.pallet_upc && <ErrorBox id="feedback">{errors.pallet_upc}</ErrorBox> }
	<label>Case Ink Jet Description:</label>
	<Field name="case_ink_jet_description" type="string" errors={errors.case_ink_jet_description} />
	{errors.case_ink_jet_description && <ErrorBox id="feedback">{errors.case_ink_jet_description}</ErrorBox> }

	<label>Brand:</label>
	<Field name="brand" type="string" errors={errors.brand} />
	{errors.brand && <ErrorBox id="feedback">{errors.brand}</ErrorBox> }
	<label>Case Weight</label>
	<Field name="case_weight" type="number" errors={errors.case_weight} />
	{errors.case_weight && <ErrorBox id="feedback">{errors.case_weight}</ErrorBox> }
	<label>Pallet Weight</label>
	<Field name="pallet_weight" type="number" errors={errors.pallet_weight} />
	{errors.pallet_weight && <ErrorBox id="feedback">{errors.pallet_weight}</ErrorBox> }
	<label>Cases Per Pallet</label>
	<Field name="cases_per_pallet" type="number" errors={errors.cases_per_pallet} />
	{errors.cases_per_pallet && <ErrorBox id="feedback">{errors.cases_per_pallet}</ErrorBox> }
	<label>Pallets Per Truck</label>
	<Field name="pallets_per_truck" type="number" errors={errors.pallets_per_truck} />
	{errors.pallets_per_truck && <ErrorBox id="feedback">{errors.pallets_per_truck}</ErrorBox> }
	<label>Case Length:</label>
	<Field name="case_length" type="number" errors={errors.case_length} />
	{errors.case_length && <ErrorBox id="feedback">{errors.case_length}</ErrorBox> }
	<label>Case Width:</label>
	<Field name="case_width" type="number" errors={errors.case_width} />
	{errors.case_width && <ErrorBox id="feedback">{errors.case_width}</ErrorBox> }
	<label>Case Height:</label>
	<Field name="case_height" type="number" errors={errors.case_height} />
	{errors.case_height && <ErrorBox id="feedback">{errors.case_height}</ErrorBox> }
	<label>Cube:</label>
	<Field name="cube" type="number" errors={errors.cube} />
	{errors.cube && <ErrorBox id="feedback">{errors.cube}</ErrorBox> }
	<label>ti:</label>
	<Field name="ti" type="number" errors={errors.ti} />
	{errors.ti && <ErrorBox id="feedback">{errors.ti}</ErrorBox> }
	<label>hi:</label>
	<Field name="hi" type="number" errors={errors.hi} />
	{errors.hi && <ErrorBox  id="feedback">{errors.hi}</ErrorBox> }
	<label>allergens:</label>
	<Field name="allergens" type="string" errors={errors.allergens} />
	{errors.allergens && <ErrorBox  id="feedback">{errors.allergens}</ErrorBox> }
	<label>Current Description:</label>
	<Field name="current_description" type="string" errors={errors.current_description} />
	{errors.current_description && <ErrorBox id="feedback">{errors.current_description}</ErrorBox> }
	<label>Organic:</label>
	<Box display="flex" flexDirection="row">
	  <Field name="organic" type="checkbox" errors={errors.organic} />
	  {`${values.organic}`}
	  {errors.organic && <ErrorBox id="feedback">{errors.organic}</ErrorBox> }
	</Box>
	<label>GMO:</label>
	<Box display="flex" flexDirection="row">
	  <Field name="gmo" type="checkbox" errors={errors.gmo} />
	  {`${values.gmo}`}
	  {errors.gmo && <ErrorBox id="feedback">{errors.gmo}</ErrorBox> }
	</Box>
	<label>Is Product Active:</label>
	<Box display="flex" flexDirection="row">
	  <Field name="is_active" type="checkbox" errors={errors.is_active} />
	  {`${values.is_active}`}
	  {errors.is_active && <ErrorBox id="feedback">{errors.is_active}</ErrorBox> }
	</Box>
    </>
  )
}

