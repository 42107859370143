export const HOME = 'HOME';
export const ANALYTICS = 'ANALYTICS';
export const SALES = 'SALES';
export const OPERATIONS = 'OPERATIONS';
export const HUMAN_RESOURCES = 'HUMAN_RESOURCES';
export const MANAGEMENT = 'MANAGEMENT';
export const SETTINGS = 'SETTINGS';
export const PRODUCTION_VIEW = 'PRODUCTION_VIEW';
export const INFORMATION = 'INFORMATION';
export const MASTER_CODE_ADMIN = 'MASTER_CODE_ADMIN';

export const ROLE_ARRAY = [ANALYTICS, SALES, OPERATIONS,
			   HUMAN_RESOURCES, INFORMATION,
			   MASTER_CODE_ADMIN, MANAGEMENT]
