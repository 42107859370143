import React from 'react';
import Box from '@material-ui/core/Box';
import { ErrorBox }from '../appTools/errorComponents';
import { useField, useFormik, Formik,
	 FormikHelpers, ErrorMessage, Field,
	 FieldAttributes, FieldInputProps,
	 FieldProps, useFormikContext
} from 'formik';
import { GenericProductForm } from './GenericProductForm';
import { DrinkInterface } from './infoTypes'

export const DrinkForm = () => {
  const { values, errors } = useFormikContext<DrinkInterface>();

  return(
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <label>Cap Type:</label>
      <Field name="cap_type" type="string" errors={errors.cap_type}/>
      {errors.cap_type && <ErrorBox id="feedback">{errors.cap_type}</ErrorBox>}
      <label>Bottles:</label>
      <Field name="bottles" type="number" errors={errors.bottles}/>
      {errors.bottles && <ErrorBox id="feedback">{errors.bottles}</ErrorBox>}
      <label>Bottle Code Date:</label>
      <Field name="bottle_code_date" type="string" errors={errors.bottle_code_date}/>
      {errors.bottle_code_date && <ErrorBox id="feedback">{errors.bottle_code_date}</ErrorBox>}
      <label>GTIN:</label>
      <Field name="gtin" type="number" errors={errors.gtin}/>
      {errors.gtin && <ErrorBox id="feedback">{errors.gtin}</ErrorBox>}
      <label>Is Label Film:</label>
      <Box display="flex" flexDirection="row">
	<Field name="is_label_film" type="checkbox" errors={errors.is_label_film}/>
	{`${values.is_label_film}`}
      {errors.is_label_film && <ErrorBox id="feedback">{errors.is_label_film}</ErrorBox>}
      </Box>
      <label>Is Drink Water:</label>
      <Box display="flex" flexDirection="row">
	<Field name="is_water" type="checkbox" errors={errors.is_water}/>
	{`${values.is_water}`}
      {errors.is_water && <ErrorBox id="feedback">{errors.is_water}</ErrorBox>}
      </Box>
      <GenericProductForm />
    </Box>
  )
}


