import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useStyles } from '../../hooks/useStyles';

const Home = () => {
  const classes = useStyles();
  return(
    <Paper className={classes.paper}>
      <h1>You are viewing the empty Home Screen.</h1>
      <h2>To Interact with the PA parser, click on the operations icon to the left.</h2>
    </Paper>
  )
}

export default Home;
