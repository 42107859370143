import { NO_JSON } from '../../constants/constants';

export async function getRequest(url: string) {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  if(response.status >= 200 && response.status <= 400){
    return response.json();
  }else{
    return NO_JSON
  }
}
