import React from 'react';
import Box from '@material-ui/core/Box';
import {ErrorBox} from '../appTools/errorComponents';
import { useField, useFormik, Formik,
	 FormikHelpers, ErrorMessage, Field,
	 FieldAttributes, FieldInputProps,
	 FieldProps, useFormikContext
} from 'formik';
import { GenericProductForm } from './GenericProductForm';
import { AerosolInterface } from './infoTypes'


export const AerosolForm = () => {
  const { values, errors } = useFormikContext<AerosolInterface>();

  return(
    <Box display="flex" flexDirection="column">
      <label>Valve Type:</label>
      <Field name="valve_type" type="string" errors={errors.valve_type}/>
      {errors.valve_type && <ErrorBox id="feedback">{errors.valve_type}</ErrorBox> }
      <label>Can Size:</label>
      <Field name="can_size" type="number" errors={errors.can_size}/>
      {errors.can_size && <ErrorBox id="feedback">{errors.can_size}</ErrorBox> }
      <label>Can Type:</label>
      <Field name="can_type" type="string" errors={errors.can_type} />
      {errors.can_type && <ErrorBox id="feedback">{errors.can_type}</ErrorBox> }
      <label>Cans Per Pallet Purchased:</label>
      <Field name="cans_per_pallet_purchased" type="number" errors={errors.cans_per_pallet_purchased} />
      {errors.cans_per_pallet_purchased && <ErrorBox id="feedback">{errors.cans_per_pallet_purchased}</ErrorBox> }
      <label>Cans Per Layer Purchased:</label>
      <Field name="cans_per_layer_purchased" type="number" errors={errors.cans_per_layer_purchased} />
      {errors.cans_per_layer_purchased && <ErrorBox id="feedback">{errors.cans_per_layer_purchased}</ErrorBox> }
      <label>Cap:</label>
      <Field name="cap" type="string" errors={errors.cap} />
      {errors.cap && <ErrorBox id="feedback">{errors.cap}</ErrorBox> }
      <label>Cans:</label>
      <Field name="cans" type="number" errors={errors.cans} />
      {errors.cans && <ErrorBox id="feedback">{errors.cans}</ErrorBox> }
      <label>Can Ink Jet Description:</label>
      <Field name="can_ink_jet_description" type="string" errors={errors.can_ink_jet_description} />
      {errors.can_ink_jet_description && <ErrorBox id="feedback">{errors.can_ink_jet_description}</ErrorBox> }
      <label>Can Fill Weight:</label>
      <Field name="can_fill_weight" type="number" errors={errors.can_fill_weight} />
      {errors.can_fill_weight && <ErrorBox id="feedback">{errors.can_fill_weight}</ErrorBox> }
      <label>Shelf Life:</label>
      <Field name="shelf_life" type="number" errors={errors.shelf_life} />
      {errors.shelf_life && <ErrorBox id="feedback">{errors.shelf_life}</ErrorBox> }
      <label>Formula Quantity Per Bill:</label>
      <Field name="formula_quant_per_bill" type="number" errors={errors.formula_quant_per_bill} />
      {errors.formula_quant_per_bill && <ErrorBox id="feedback">{errors.formula_quant_per_bill}</ErrorBox> }
      <label>Raw Cream Per Bill:</label>
      <Field name="raw_cream_per_bill" type="number" errors={errors.raw_cream_per_bill} />
      {errors.raw_cream_per_bill && <ErrorBox id="feedback">{errors.raw_cream_per_bill}</ErrorBox> }
      <label>Raw Skim Per Bill:</label>
      <Field name="raw_skim_per_bill" type="number" errors={errors.raw_skim_per_bill} />
      {errors.raw_skim_per_bill && <ErrorBox id="feedback">{errors.raw_skim_per_bill}</ErrorBox> }
      <label>Nitrogen Pounds Per Case:</label>
      <Field name="nitrogen_pounds_per_case" type="number" errors={errors.nitrogen_pounds_per_case} />
      {errors.nitrogen_pounds_per_case && <ErrorBox id="feedback">{errors.nitrogen_pounds_per_case}</ErrorBox> }
      <label>Nitrous Pounds Per Case:</label>
      <Field name="nitrous_pounds_per_case" type="number" errors={errors.nitrous_pounds_per_case} />
      {errors.nitrous_pounds_per_case && <ErrorBox id="feedback">{errors.nitrous_pounds_per_case}</ErrorBox> }
      <label>Cans Per Case:</label>
      <Field name="cans_per_case" type="number" errors={errors.cans_per_case} />
      {errors.cans_per_case && <ErrorBox id="feedback">{errors.cans_per_case}</ErrorBox> }
      <label>Formula Abbreviation:</label>
      <Field name="formula_abbrev" type="string" errors={errors.formula_abbrev} />
      {errors.formula_abbrev && <ErrorBox id="feedback">{errors.formula_abbrev}</ErrorBox> }
      <label>Twelve Count Eq.:</label>
      <Field name="twelve_count_eq" type="number" errors={errors.twelve_count_eq} />
      {errors.twelve_count_eq && <ErrorBox id="feedback">{errors.twelve_count_eq}</ErrorBox> }
      <GenericProductForm/>
    </Box>
  )
}


