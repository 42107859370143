// Development URLS


let afiMonoURL: string
if(process.env.NODE_ENV == 'production'){
  afiMonoURL = "https://afimono.afiauth.net"
} else {
  afiMonoURL = "http://127.0.0.1:8000"
}



export const afiMonoApiURL: string = `${afiMonoURL}/api`

export const aerosolReqURL: string = `${afiMonoApiURL}/aerosol_list/`
export const drinkReqURL: string = `${afiMonoApiURL}/drink_list/`
export const popReqURL: string = `${afiMonoApiURL}/pop_list/`
export const uploadObjectURL: string = `${afiMonoApiURL}/upload_object/`


// Production URLS
export const kongGateway: string = "https://afitest.net:8443"


export const pallasServerAddress: string = "https://pallasparsing.net"
export const pallasAddress: string = `${kongGateway}/pallas`

// Urls for file parsing
