import { GenericProductInterface,
	 AerosolInterface,
	 DrinkInterface
} from './infoTypes'

const defaultGenericForm = {
  last_edit_author: 'empty',
  item_code: 'empty',
  label_no: 0,
  box_type: 'empty',
  upc: 'empty',
  units: 0,
  case_upc: 'empty',
  pallet_upc: 'empty',
  case_ink_jet_description: "empty",
  brand: 'empty',
  case_weight: 0,
  pallet_weight: 0,
  cases_per_pallet: 0,
  pallets_per_truck: 0,
  case_length: 0,
  case_width: 0,
  case_height: 0,
  cube: 0,
  ti: 0,
  hi: 0,
  current_description: 'empty',
  organic: false,
  gmo: false,
  is_active: true 
} as GenericProductInterface



let rawDrink = {
  cap_type: 'empty',
  bottles: 0,
  bottle_code_date: 'empty',
  is_water: false,
  gtin: 0,
  is_label_film: false
}

let rawAerosol = {
  valve_type: 'empty',
  can_size: 0,
  cans_per_pallet_purchased: 0,
  cans_per_layer_purchased: 0,
  cap: 'empty',
  cans: 0,
  can_ink_jet_description: 'empty',
  can_fill_weight: 0,
  shelf_life: 0,
  formula_quant_per_bill: 0,
  raw_cream_per_bill: 0,
  raw_skim_per_bill: 0,
  nitrogen_pounds_per_case: 0,
  nitrous_pounds_per_case: 0,
  cans_per_case: 0,
  formula_abbrev: 'empty',
  twelve_count_eq: 0
}

let rawPop = {
  film_type: 'empty',
  p182_eq: 0.05
}


export const defaultPop = Object.assign(defaultGenericForm, rawPop)

export const defaultFormValues = Object.assign(defaultGenericForm,
					       rawDrink, rawAerosol, rawPop) 
