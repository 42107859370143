import React from 'react';
import { useStyles } from '../../hooks/useStyles';
import Paper from '@material-ui/core/Paper'

const ProductionView = () => {
  const classes = useStyles();
  return(
    <Paper className={classes.paper}>
      <h1>hello from Production View</h1>
    </Paper>
    
  )
}

export default ProductionView;
